import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Dialog, DialogContent, DialogActions, CircularProgress, DialogTitle, Grid } from '@material-ui/core'
import { Link } from 'react-router-dom'
import TextField from '@material-ui/core/TextField';
import Layout from '../Layout'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card'
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import InputAdornment from '@material-ui/core/InputAdornment';
import { sendReportData, clearSendReportError } from '../../actions/addLinkedAccounts';
import clsx from 'clsx';


const withStyles = makeStyles(() => ({
    requestReportRoot: {
        minHeight: "100vh",
        width: "100%",
        flexDirection: "column",
        justifyContent: "center"
    },
    requestReportHeaderWrapper: {
        display: "flex",
        flexDirection: "column",
        margin: "15px"
    },
    requestReportTopHeader: {
        textAlign: "left",
        fontSize: "0.75rem"
    },
    requestReportHeaderText: {
        textAlign: "left",
        fontSize: "1.5rem"
    },
    recipientHeader: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        width: "80%",
        marginTop: "40px",
        marginLeft: "30px",
        marginBottom: "20px"
    },
    recipientHeaderText: {
        color: "#313131",
        textAlign: "left",
    },
    visibleInformation: {
        color: "gray",
        fontSize: "0.75rem",
        textAlign: "left",
        marginLeft: "5px",
        lineHeight: "2"
    },
    reportConfirmedText: {
        color: "#313131",
        fontSize: "0.75rem"
    },
    reportMessageText: {
        color: "#949494",
        fontSize: "0.75rem",
        textAlign: "left",
        margin: "15px"
    },
    visibleInfoWrapper: {
        display: "flex"
    },
    reviewReportProofAmount: {
        color: "#313131",
        marginTop: "10px"
    },
    reqMinReport: {
        display: "flex",
        flexDirection: "column",
        height: "85px",
        backgroundColor: "#B8FBE2",
        borderRadius: "16px",
        width: "50%",
        justifyContent: "center",
        alignItems: "center"
    },
    reqMinReportWrapper: {
        display: "flex",
        padding: "10px 0 10px 0"
    },
    dividerText: {
        color: "#313131",
        textAlign: "left",
        fontSize: "0.6rem",
        marginTop: "10px"
    },
    reqMinReportTitle: {
        color: "#949494",
        textAlign: "left",
        fontSize: "0.7rem",
        marginTop: "-10px"
    },
    optionalMessage: {
        color: "rgba(0, 0, 0, 0.54)",
        textAlign: "left",
        fontSize: "0.6rem",
        margin: "10px 0 10px 0"
    },
    messageSubjectHeader: {
        color: "#313131",
        textAlign: "left",
    },
    messageSubjectWrapper: {
        // display: "flex"
    },
    fullNameEmailText: {
        color: "#313131",
        textAlign: "left",
        marginRight: "50px",
        fontSize: "0.75rem",
        margin: "5px"
    },
    dbTopText: {
        paddingTop: "3%",
        paddingLeft: "5%",
        paddingRight: "3%",
        paddingRight: "2%",
        textAlign: "left"
      },
    selectTemplateWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        width: "80%",
        marginTop: "40px",
        marginLeft: "30px",
        marginBottom: "20px"
    },
    selectTemplateHeaderText: {
        color: "#313131",
        textAlign: "left"
    },
    selectTemplateCard: {
        display: "flex",
        margin: "15px 0 15px 0",
        padding: "10px",
        borderRadius: "15px"
    },
    selectInformationWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        width: "80%",
        marginTop: "40px",
        marginLeft: "30px",
        marginBottom: "20px"
    },
    checkIcon: {
        width: "15px",
        height: "15px",
        margin: "5px"
    },
    checkIconReport: {
        width: "15px",
        height: "15px",
        margin: "5px 0 5px 0"
    },
    checkIconReportConfirmed: {
        width: "40px",
        height: "40px",
        margin: "5px 0 5px 0"
    },
    reportConfirmedWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "50%"
    },
    optionalInfoCheckboxText: {
        color: "#32A19F"
    },
    optionalInfoCheckbox: {
        color: "#313131",
        "& span": {
            fontSize: "0.75rem"
        }
    },
    optionalInfoCheckboxColor: {
        color: "#32A19F"
    },
    nextStepButton: {
        display: "flex",
        justifyContent: "flex-end",
    },
    reviewButtonWrapper: {
        display: "flex",
        justifyContent: "flex-end"
    },
    formControl: {
        width: "100%"
    },
    selectTemplateRadioLabel: {
        fontSize: "0.75rem",
        textAlign: "left"
    },
    radioRoot: {
        '&$checked': {
            color: "#32A19F"
        }
    },
    checked: {},
    selectMinimumCard: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "10px 0px 10px 0px",
        padding: "15px",
        backgroundColor: "#d2e3f66b"
    },
    selectMinimumInput: {
        width: "80%",
        "& label": {
            width: "130%",
            textAlign: "center",
        }
    },
    selectMinInputLabel: {
        color: "rgba(0, 0, 0, 0.54) !important"
    },
    requiredMinHeader: {
        textAlign: "center"
    },
    minAmountInput: {
        "&:before": {
            borderBottom: "1px solid #E4E2E2"
        },
        "&:after": {
            borderBottom: "1px solid #E4E2E2"
        }
    },
    selectAccountsTopCardWrapper: {
        display: "flex",
        flexDirection: "column"
    },
    totalSelectedHeader: {
        fontSize: "0.75rem",
        color: "rgba(0, 0, 0, 0.54)"
    },
    accountsWrapper: {
        marginBottom: "10px",
        marginLeft: "45px"
    },
    subAccountsWrapper: {
        display: "flex",
        flexDirection: "column"
    },
    linkedAccountsBankname: {
        fontSize: "0.75rem",
        color: "#313131",
    },
    linkedAccountsSubaccounts: {
        color: "#949494",
        fontSize: "0.65rem",
        margin: "10px 0"
    },
    linkedAccountsSubaccountsWrapper: {
        display: "flex",
        justifyContent: "space-between",
        marginRight: "16px"
    },
    accountsNumber: {
        color: "black",
        fontSize: "0.65rem",
        marginBottom: "10px",
        textAlign: "left",
    },
    accountsDivider: {
        marginLeft: "-45px"
    },
    selectAccountsCard: {
        margin: "20px 0 20px 0"
    },
    bankTitleWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start"
    },
    bankTitleSumWrapper: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "10px"
    },
    cancelButtonWrapper: {
        display: "flex",
        margin: "20px 0 20px 0",
        "& a ": {
            textDecoration: "none"
        }
    },
    cancelButtonRoot: {
        backgroundColor: "rgba(210,227,246,0.30)",
        borderRadius: "10px",
        height: "50px",
        width: "100px",
        "& span": {
            textTransform: "none"
        }
    },
    messageBodyRoot: {
        "& > div": {
            // height: "150px",
            display: "flex",
            flexDirection: "column"
        },
        marginTop: "2%",
        paddingRight: "10%",
         // Root class for the input field
        // "& .MuiOutlinedInput-root:hover": {
        //     color: "#red",
        //     // fontFamily: "Arial",
        //     // fontWeight: "bold",
        //     // Class for the border around the input field
        //     "& .MuiOutlinedInput-notchedOutline": {
        //         borderColor: "#red",
        //         // fontWeight: "bold",
        //     },
        //     borderColor: "#red",
        //     borderWidth: "2px",
        // },
        // // Class for the label of the input field
        // "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        //     color: "#red",
        //     // fontWeight: "bold",
        // },
        // "& .MuiOutlinedInput-notchedOutline": {
        //     borderColor: "#red",
        //     borderWidth: "2px",
        // },
    },
    messageBodyColor: {
        // "&$focused": {
        //     color: "red"
        // }
    },
    reviewButtonText: {
        color: "#32A19F",
        textTransform: "none"
    },
    sendReportButtonWrapper: {
        display: "flex",
        justifyContent: "flex-end"
    },
    sendReportButton: {
        backgroundColor: "transparent",
        "& span": {
            textTransform: "none"
        }
    },
    selectTemplateBorder: {
        border: "2px solid #32A19F",
        borderRadius: "16px"
    },
    loadingDialog: {
        backgroundColor: "#000",
        opacity: ".7",
        color: 'white',
        paddingBottom: "20px"
      },
      spinner: {
          marginRight: '20px'
          
      },
      dialogText: {
          fontSize: '1.15rem'
      },
      dialogButton: {
        color: "white"
      },
      requestReportWrapper: {
        background: "#ffffff",
        borderRadius: "51px",
        borderTopRightRadius: "51px",
        marginLeft: '5%',
        marginRight: '5%',
        background: "#ffffff",
      },
      styledFormLabel: {
        fontWeight: "bold",
        color: "#32A19F",
        fontSize: "1.2em"
      },
      
}));

const mapStateToProps = (state) => ({
    token: state.token,
    dashboardData: state.dashboardData
})

const RequestReport = (props) => {
    const classes = withStyles();
    const dispatch = useDispatch();
    const [token, setToken] = useState(null || props.token);
    const [sendReportStep, setSendReportStep] = useState(0);
    const [selectInfo, setSelectInfo] = useState({
        address: false,
        phone: false,
        accountNumber: false,
        transactions: false,
        report_type: 1,
        report_request_type: 1,
        message_body: null,
        message_subject: "",
        to_user: "",
        proof_amount: 0,
        message_draft: 0,
        data: []
    });
    const [selectedReportType, setSelectedReportType] = useState('');
    const [templateBorder, setTemplateBorder] = useState(false);
    const [stepZeroValid, setStepZeroValid] = useState(false);
    const [stepOneValid, setStepOneValid] = useState(false);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState();
    const [showLoadingModal, setShowLoadingModal] = useState(false);
    const [myAccountData, setMyAccountData] = useState(null);

    const { address, phone, accountNumber, transactions, report_type, report_request_type, message_body, message_subject, message_draft, to_user, data, proof_amount } = selectInfo;

    const { reportSent, errorSendingReport, errorMsg } = useSelector((state) => state);
    const [showErrorModal, setShowErrorModal] = useState(false);

    useEffect(() => {
        if (getEmailErrorMessage(selectInfo.to_user) === "") {
            setStepZeroValid(true)
        } else {
            setStepZeroValid(false);
        }

    }, [selectInfo.to_user])

    useEffect(() => {
        fetch(`${process.env.REACT_APP_PAYVIEW_BASE_URL}/api/myaccount`, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        })
          .then(res => res.json())
          .then(
            (result) => {
              setMyAccountData(result);
              console.log(result)
            },
            (error) => {
              setError(error);
            }
          )
      }, [])

    useEffect(() => {
        if (selectInfo.proof_amount > 0 && selectInfo.report_type) {
            setStepOneValid(true)
        } else {
            setStepOneValid(false)
        }

    }, [selectInfo.proof_amount, selectInfo.report_type])


    useEffect(() => {
        if(errorSendingReport) {
            setShowLoadingModal(false);
            setShowErrorModal(true);
        } 
    }, [errorSendingReport]);

    useEffect(() => {
        if(errorMsg) {
            console.log('setting message')
            setMessage(errorMsg)
        }
    }, [errorMsg]);
    

    const handleToUser = (event) => {
        setSelectInfo({ ...selectInfo, to_user: event.target.value });
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(event)
        setSendReportStep((step) => step + 1)
        console.log(selectInfo)
    };

    const handleReportType = (event) => {
        setSelectedReportType(event.target.value);
        setTemplateBorder(event.target.value);
        setSelectInfo({ ...selectInfo, report_type: parseInt(event.target.value, 10) });
        console.log(event.target.value)
        setError(false);
    };

    const handleAddress = (event) => {
        setSelectInfo({ ...selectInfo, [event.target.name]: event.target.checked });
    };

    const handlePhone = (event) => {
        setSelectInfo({ ...selectInfo, [event.target.name]: event.target.checked });
    };

    const handleAccountNumber = (event) => {
        setSelectInfo({ ...selectInfo, [event.target.name]: event.target.checked });
    };

    const handleTransactions = (event) => {
        // setSelectInfo({ ...selectInfo, [event.target.name]: event.target.checked });
    };

    const handleRequiredMin = (event) => {
        setSelectInfo({ ...selectInfo,proof_amount: event.target.value })
    }

    const amtFocus = () => {
        if(selectInfo.proof_amount === 0) {
            setSelectInfo({ ...selectInfo,proof_amount:'' })
        }
    }
    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    const formatNumbers = (num) => {
        if (num % 1 !== 0) {
            let localeNum = num.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
            return localeNum;
        } else {
            return num.toLocalString();
        }
    }

    const sendReportBack = () => {
        setSendReportStep((step) => step - 1)
    }

    const saveReportDraft = () => {
        setSelectInfo({ ...selectInfo, message_draft: 0 })
    }

    const sendReport = useCallback(() => {
        setShowLoadingModal(true);
        console.log(selectInfo);
        dispatch(sendReportData(selectInfo))
    }, [dispatch, selectInfo]);

    const handleMessageBody = (event) => {
        setSelectInfo({ ...selectInfo, message_body: event.target.value });
    }

    const handleMessageSubject = (event) => {
        setSelectInfo({ ...selectInfo, message_subject: event.target.value });
    }

    const handleErrorModalClose= () => {
        setShowErrorModal(false);
        dispatch(clearSendReportError());
    };
    
    const getEmailErrorMessage= (to_user) => {
        if(to_user && !validateEmail(to_user)) {
            return "Enter a valid email address."
        } 
        else if(myAccountData && myAccountData.accountInfo && myAccountData.accountInfo.length > 0 && myAccountData.accountInfo[0].email === to_user) {
            return "Enter a different email from your account email."
        }
        return '';
    };

    const renderFormStep = (step) => {
        if (step === 0) {
            return (
                <>
                    <div className={classes.recipientHeader}>
                        <Typography className={classes.recipientHeaderText}>Recipient</Typography>
                        <form className={classes.emailInput} autoComplete="on"  style={{ display: "flex", marginBottom: "30px"}}>
                            <TextField
                                fullWidth={true}
                                id="recipient"
                                label="Email"
                                value={to_user}
                                onChange={handleToUser}
                                InputLabelProps={{
                                    style: { fontSize: "0.75rem" }
                                }}
                                error={!!(!stepZeroValid && to_user)}
                                helperText={getEmailErrorMessage(to_user)}
                            />
                        </form>
                        <Typography className={classes.recipientHeaderText}>Required Information</Typography>
                        <div style={{ display: "flex" }}>
                                <form className={classes.formControl} onSubmit={handleSubmit}>
                                    <FormControl component="fieldset" className={classes.formControl}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        className={classes.optionalInfoCheckboxText}
                                                        color="default"
                                                        checked={address}
                                                        onChange={handleAddress}
                                                        name="address"
                                                    />
                                                }
                                                label="Address"
                                                className={classes.optionalInfoCheckbox}
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        className={classes.optionalInfoCheckboxText}
                                                        color="default"
                                                        checked={phone}
                                                        onChange={handlePhone}
                                                        name="phone"
                                                    />
                                                }
                                                label="Phone"
                                                className={classes.optionalInfoCheckbox}

                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        className={classes.optionalInfoCheckboxText}
                                                        color="default"
                                                        checked={accountNumber}
                                                        onChange={handleAccountNumber}
                                                        name="accountNumber"
                                                    />
                                                }
                                                label="Account Number"
                                                className={classes.optionalInfoCheckbox}

                                            />
                                            {/* API NOT IMPLEMENTED YET 
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        className={classes.optionalInfoCheckboxText}
                                                        color="default"
                                                        checked={transactions}
                                                        onChange={handleTransactions}
                                                        name="transactions"
                                                    />
                                                }
                                                label="Transactions"
                                                className={classes.optionalInfoCheckbox}

                                            /> */}
                                        </FormGroup>
                                        {stepZeroValid === true ? (
                                            <Button type="submit" className={classes.nextStepButton}>
                                                <img src="/icons/Button/Next_Color_On.svg" />
                                            </Button>
                                        ) : null}
                                    </FormControl>
                                </form>
                        </div>
                        <div className={classes.cancelButtonWrapper}>
                            <Link to="/communications">
                                <Button
                                    classes={{
                                        root: classes.cancelButtonRoot
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Link>
                        </div>
                    </div>
                </>
            )
        } else if (step === 1) {
            return (
                <>
                    <div className={classes.recipientHeader}>
                        <Typography className={classes.recipientHeaderText}>Minimum Funds Required</Typography>
                        <form style={{ display: "flex", marginBottom: "30px"}}>
                            <TextField
                                fullWidth={true}
                                id="required-amount"
                                label="Enter value (USD)"
                                value={proof_amount}
                                onChange={handleRequiredMin}
                                onFocus={amtFocus}
                                InputLabelProps={{
                                    style: { fontSize: "0.75rem" }
                                }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    inputMode: 'numeric'
                                }}
                            />
                        </form>
                        <Typography className={classes.recipientHeaderText}>Request Template</Typography>
                        <div style={{ display: "flex" }}>
                            <form onSubmit={handleSubmit}>
                                <FormControl component="fieldset" error={!!(error)} className={classes.formControl}>
                                    <RadioGroup aria-label="selectTemplate" name="selectTemplateRadioGroup" value={selectedReportType} onChange={handleReportType}>
                                        <Card className={clsx(classes.selectTemplateCard, {
                                            [classes.selectTemplateBorder]: templateBorder === "1"
                                        })}>
                                            <FormControlLabel
                                                value="1"
                                                control={
                                                    <Radio classes={{
                                                        root: classes.radioRoot,
                                                        checked: classes.checked
                                                    }}
                                                    />
                                                }
                                                label={<Typography className={classes.selectTemplateRadioLabel}>Confirmation of Available Funds</Typography>}
                                            />
                                        </Card>
                                        <Card className={clsx(classes.selectTemplateCard, {
                                            [classes.selectTemplateBorder]: templateBorder === "2"
                                        })}>
                                            <FormControlLabel
                                                value="2"
                                                control={
                                                    <Radio classes={{
                                                        root: classes.radioRoot,
                                                        checked: classes.checked
                                                    }}
                                                    />
                                                }
                                                label={<Typography className={classes.selectTemplateRadioLabel}>Share Account Values Only</Typography>}
                                            />
                                        </Card>
                                        {/* <Card  className={clsx(classes.selectTemplateCard, {
                                            [classes.selectTemplateBorder]: templateBorder === "3"
                                        })}>
                                            <FormControlLabel
                                                value="3"
                                                control={
                                                    <Radio classes={{
                                                        root: classes.radioRoot,
                                                        checked: classes.checked
                                                    }}
                                                    />
                                                }
                                                label={<Typography className={classes.selectTemplateRadioLabel}>Share Account Values With Detailed Transactions</Typography>}
                                            />
                                        </Card> */}
                                    </RadioGroup>
                                    {stepOneValid === true ? (
                                        <Button type="submit" className={classes.nextStepButton}>
                                            <img src="/icons/Button/Next_Color_On.svg" />
                                        </Button>
                                    ) : null}

                                </FormControl>
                            </form>
                        </div>
                        <div className={classes.cancelButtonWrapper}>
                            <Button
                                onClick={sendReportBack}
                                classes={{
                                    root: classes.cancelButtonRoot
                                }}
                            >
                                Back
                            </Button>
                        </div>
                    </div>
                </>
            )
        } else if (step === 2) {
            return (
                <>
                    <div className={classes.selectInformationWrapper}>
                        <Typography className={classes.recipientHeaderText}>Include Additional Message?</Typography>
                        <Typography className={classes.optionalMessage}>Optional</Typography>
                        <form onSubmit={handleSubmit}>
                            <div className={classes.messageSubjectWrapper}>
                                <Typography className={classes.messageSubjectHeader}>Title:</Typography>
                                <TextField
                                    multiline={false}
                                    value={message_subject}
                                    onChange={handleMessageSubject}
                                    variant="outlined"
                                    fullWidth={true}
                                    placeholder="Give your message a title..."
                                >

                                </TextField>

                            </div>

                            <div>
                                <Typography className={classes.messageSubjectHeader}>Message:</Typography>
                                <TextField
                                    id="outlined-multiline-static"
                                    multiline={true}
                                    maxRows={8}
                                    value={message_body}
                                    onChange={handleMessageBody}
                                    fullWidth={true}
                                    variant="outlined"
                                    autoFocus={false}
                                    placeholder="Type your message here..."
                                    classes={{
                                        root: classes.messageBodyRoot,
                                    }}
                                >
                                </TextField>
                            </div>
                            <div className={classes.sendReportButtonWrapper}>
                            <Button
                                onClick={() => sendReport()}
                                className={classes.nextStepButton}>
                                <Typography className={classes.reviewButtonText}>Send</Typography>
                                <img src="/icons/Button/Mail_On.svg" />
                            </Button>
                        </div>
                        </form>
                        <div className={classes.cancelButtonWrapper}>
                            <Button
                                onClick={sendReportBack}
                                classes={{
                                    root: classes.cancelButtonRoot
                                }}
                            >
                                Back
                            </Button>
                        </div>
                    </div>
                    <Dialog
                        open={showLoadingModal}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent className={classes.loadingDialog} style={{display: 'flex', justifyContent: 'center'}}>
                            <Typography variant='h6' className={classes.dialogText} style={{display: 'flex', justifyContent: 'center'}}>
                                <CircularProgress className={classes.spinner} color="inherit" />
                                  Creating Request...
                            </Typography>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        open={showErrorModal}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle className={classes.loadingDialog}>ERROR</DialogTitle>
                        <DialogContent className={classes.loadingDialog} style={{display: 'flex', justifyContent: 'center'}}>
                            <Typography variant='h6' className={classes.dialogText} style={{display: 'flex', justifyContent: 'center'}}>
                                    {message ? message : "Failed to send report. Please try again."}
                            </Typography>
                        </DialogContent>
                        <DialogActions className={classes.loadingDialog}>
                            <Button className={classes.dialogButton} onClick={() => handleErrorModalClose()}>
                            Okay
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            )
        }
    }

    return (
        <div className={classes.requestReportRoot}>
            <Layout pageTitle={props.title} />
            <div className={classes.dbTopText} style={{justifyContent: "left", display: "flex"}}>
                <Link to="/dashboard" style={{textDecoration: "none", display: "flex", marginRight: "3%",}}>
                    <h3 style={{height: '100%', color: "#fff", cursor: "pointer"}}>&lt;</h3>
                </Link>
                <div style={{height: '100%'}}>
                    <h2 style={{marginBottom: 0, marginTop: 0, fontWeight: 400}}>Request a Report</h2>
                    <p style={{marginBottom: '3%', fontSize: '70%', marginTop: "0", fontWeight: "400"}}>Quickly and securely request the necessary documentation to verify your client's purchasing power.</p>
                </div>
            </div>
            <div className={classes.requestReportWrapper}>
                <Grid container spacing={2} style={{color: '#000', textAlign: 'left'}}>
                    <Grid item xs={6} style={{paddingLeft: '5%', paddingTop: '2%', marginTop: '2%', marginBottom: '5%'}}>
                        <p style={{'margin':  0,'fontWeight': 'bold','fontSize': '70%', marginBottom: "6%"}}>Client Information</p>
                        <form style={{ display: "flex", marginBottom: "10%"}}>
                            <TextField
                                fullWidth={true}
                                id="recipient"
                                label="Email"
                                value={to_user}
                                onChange={handleToUser}
                                InputLabelProps={{
                                    style: {fontWeight: "bold", color: "#32A19F", fontSize: ".8em", paddingBottom: "1%", borderColor: "red"}
                                }}
                                error={!!(!stepZeroValid && to_user)}
                                helperText={getEmailErrorMessage(to_user)}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                    // inputMode: 'numeric'
                                }}
                            />
                        </form>
                        <form style={{ display: "flex", marginBottom: "30px"}}>
                            <TextField
                                fullWidth={true}
                                id="required-amount"
                                label="Minimum Required Funds"
                                value={proof_amount}
                                onChange={handleRequiredMin}
                                onFocus={amtFocus}
                                sx={{
                                    '& .MuiInputBase-root.MuiFilledInput-root:hover': {
                                      borderBottomColor: 'pink',
                                    },
                                }}                                
                                InputLabelProps={{
                                    style: {fontWeight: "bold", color: "#32A19F", fontSize: ".8em"}
                                }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    inputMode: 'numeric'
                                }}
                            />
                        </form>
                    </Grid>
                    <Grid item xs={6} style={{color: '#000', textAlign: 'left', paddingLeft: '3%', paddingTop: '2%', marginTop: '2%'}} >
                        <p style={{'margin':  0,'fontWeight': 'bold','fontSize': '70%', marginBottom: "2%"}}>Additional Message</p>
                        <label style={{fontWeight: "bold", color: "#32A19F", fontSize: ".6em"}} >Add a Personal Note</label>
                        <form style={{ display: "flex", marginBottom: "30px"}}>
                            
                            <TextField
                                    id="outlined-multiline-static"
                                    multiline={true}
                                    minRows={4}
                                    maxRows={8}
                                    value={message_body}
                                    onChange={handleMessageBody}
                                    fullWidth={true}
                                    variant="outlined"
                                    autoFocus={false}
                                    sx={{
                                        "& .MuiInputBase-root.Mui-disabled": {
                                            "& > fieldset": {
                                                borderColor: "pink"
                                            }
                                        }
                                    }}
                                    placeholder="Type your message here..."
                                    classes={{
                                        root: classes.messageBodyRoot,
                                    }}
                                    InputLabelProps={{
                                        style: {fontWeight: "bold", color: "#32A19F", fontSize: ".8em"}
                                    }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"></InputAdornment>
                                    }}
                                >
                                </TextField>
                        </form>
                    </Grid>
                </Grid>
                <div style={{display: "flex", alignItems: "right", justifyContent: "right", marginRight:"5%", paddingBottom: "4%"}}>
                    <Link to="/dashboard" style={{textDecoration: "none", paddingLeft: "2%", paddingRight: "2%", marginLeft: "1%",}}>
                        <Button style={{backgroundColor: "#EFF3F8",  color: "#32A19F",  fontWeight: "bold", textTransform: "none", paddingTop:"12%", paddingBottom:"12%"}}> &nbsp;&nbsp;&lt; Cancel &nbsp;&nbsp;</Button>
                    </Link>
                    <Button onClick={() => sendReport()} style={{backgroundColor: "#32A19F", padding: "1%", paddingLeft: "3%", paddingRight: "3%", color: "#fff", marginLeft: "2%", fontWeight: "bold", textTransform: "none"}}>Send Request &gt;</Button>
                </div>
            </div>
        </div>
    )
}

export default connect(mapStateToProps)(RequestReport);