import React, { useState, useEffect, useSelector, useCallback } from "react";
import { usePlaidLink } from "react-plaid-link";
import Layout from "./Layout";
import Footer from "./Footer";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Chip, Grid,Dialog, SvgIcon, DialogContent, DialogActions, DialogTitle, CircularProgress, Table, TableBody, TableRow, TableCell } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import axios from "axios";
import {
  addLinkedAccount,
  fetchDashboardData,
  clearDashboardError
} from "../actions/addLinkedAccounts.js";
import { Link } from "react-router-dom";



const withStyles = makeStyles(() => ({
  dashboardRoot: {
    minHeight: "100vh",
    width: "100%",
    // display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    overflow: "auto",
  },
  root: {
    "&[disabled]": {
      color: "grey",
      cursor: "default",
      "&:hover": {
        textDecoration: "none"
      }
    }
  },
  linkedAssetWrapper: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    padding: "15px",
  },
  linkedAssetNum: {
    fontSize: "44px",
    fontWeight: "500",
  },
  linkedAssetTitle: {
    fontSize: "18px",
    fontWeight: "300",
  },
  dbTopText: {
    paddingTop: "3%",
    paddingLeft: "5%",
    paddingRight: "3%",
    paddingRight: "2%",
    textAlign: "left"
  },
  linkedAccountsCardsWrapper: {
    justifyContent: "space-evenly",
    whiteSpace: "nowrap",
    position: "relative",
    overflowY: "hidden",
  },
  linkedAccountsCardsWhite: {
    overflowX: "scroll",
  },
  linkedAccountsCards: {
    padding: "15px",
    maxWidth: "12vh",
    height: "15vh",
    marginRight: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: "20px",
    boxShadow: "0 6px 22px 0 RGBA(91, 91, 91, 0.5)",
    display: "inline-block",
  },
  linkAccountsAddHex: {},
  requestIcon: {
    // height: "auto",
    // margin: "auto",
    // marginTop: "0px",
    // marginBottom: "10px",
    width: "30%",
    marginTop: "4%",
    margin: "auto",
    marginBottom: "6%"
  },
  requestCardTitle: {
    marginBottom: "10px",
    marginTop: "0",
    fontSize: "1rem",
    textAlign: "center",
    fontWeight: "700",
    color: "#313131",
  },
  fundsProofCardWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  fundsProofWrapper: {
    background: "#ffffff",
    borderRadius: "51px",
    borderTopRightRadius: "51px",
    marginLeft: '5%',
    marginRight: '5%',
    background: "#ffffff",
  },
  fundsProofHeader: {
    color: "#313131",
    textAlign: "left",
    fontSize: "18px",
    fontWeight: "700",
    marginTop: "15px",
  },
  fundsProofSubHeader: {
    fontSize: "16px",
    color: "#313131",
    fontWeight: "600",
    textAlign: "left",
  },
  fundsProofHeaderWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    marginTop: "40px",
    marginLeft: "30px",
    marginBottom: "20px",
  },
  notificationsWrapper: {
    display: "flex",
    flexDirection: "column",
    color: "#313131",
    marginTop: "20px",
    fontSize: "1.0rem",
    "& p": {
      fontWeight: "700",
    },
  },
  newMessagesWrapper: {
    display: "flex",
    justifyContent: "space-between",
    margin: "10px",
    background: "rgba(210,227,246,0.30)",
    borderRadius: "14px",
    height: "40px",
  },
  messageCount: {
    borderRadius: "20px",
    backgroundColor: "#44a2a0",
    height: "60%",
    width: "25px",
    color: "#fff",
    margin: "8px",
    fontSize: "14px",
  },
  bankName1: {
    fontSize: "11px",
    fontWeight: "400",
    textAlign: "left",
    float: "left",
    width: "100%",
  },
  bankAmount: {
    fontSize: "18px",
    fontWeight: "700",
    color: "#464646",
    marginBottom: "10px",
    float: "left",
    width: "100%",
  },
  accountImg: {
    float: "left",
    width: "35%",
    marginBottom: "10px",
  },
  paperRoot: {
    width: "100%",
    margin: "0 auto",
  },
  linkAccountsAddHex: {
    width: "auto",
    height: "30px",
    marginBottom: "auto",
  },
  bankName: {
    fontSize: "0.9rem",
    textAlign: "left",
  },
  accountValue: {
    fontSize: "1rem"
  },
  bankNameAccountCount: {
    fontSize: "0.65rem",
    textAlign: "left",
  },
  bankNameWrapper: {
    marginBottom: "5px"
  },
  requestCard: {
    height: "15vw",
    width: "25vw",
    minWdth: "25vw",
    borderRadius: "14px",
    marginRight: "5px",
    marginLeft: "5px",
    boxShadow: "0 6px 22px 0 rgba(91,91,91,0.25)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center", 
    marginTop: "15px"
    // paddingTop: "6%",
    // paddingBottom: "6%"
  },
  fundsProofCardWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  fundsProofHeader: {
    color: "#313131",
    textAlign: "left",
  },
  fundsProofSubHeader: {
    fontSize: "0.75rem",
    color: "#313131",
    textAlign: "left",
  },
  notificationsWrapper: {
    display: "flex",
    flexDirection: "column",
    color: "#313131",
    marginTop: "20px",
    marginLeft: "20px",
    marginRight: "20px",
    fontSize: "1.0rem",
  },
  newMessagesWrapper: {
    display: "flex",
    justifyContent: "space-between",
    margin: "10px",
    background: "rgba(210,227,246,0.30)",
    borderRadius: "14px",
    height: "40px",
  },
  messageCount: {
    borderRadius: "20px",
    backgroundColor: "#44a2a0",
    height: "60%",
    width: "25px",
    color: "white",
    margin: "8px",
  },
  popover: {
    backgroundColor: "#000",
    opacity: ".7",
    color: "#fff",
  },
  popoverDiv: {
    textAlign: "left",
    fontSize: "1.5rem",
    marginLeft: "2vh",
    marginRight: "2vh",
    padding: "2vh",
    color: "white",
    backgroundColor: "#13474A",
    height: "16vh",
    width: "30vh",
    borderRadius: "2vh",
  },
  popoverCloseButton: {
    color: "white",
    float: "right",
    left: "3.5vh",
    bottom: "2vh",
    fontSize: "1.3rem",
  },
  popoverOutline: {
    outline: "solid grey 2px",
  },
  bankLogos: {
    height: "22px",
    width: "22px",
    margin: "auto",
  },
  bankLogoWrapper: {
    display: "flex",
    justifyContent: "center",
    /* vertical-align: -webkit-baseline-middle, */
    backgroundColor: "white",
    boxShadow: "0px 0px 5px 5px rgb(187 183 183 / 26%)",
    borderRadius: "30px",
    width: "27px",
    height: "27px",
  },
  linkedAccountsBankWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%"
  },
  loadingDialog: {
    backgroundColor: "#000",
    opacity: ".7",
    color: 'white',
    paddingBottom: "20px"
  },
  spinner: {
      marginRight: '20px'
      
  },
  dialogText: {
      fontSize: '1.15rem'
  },
  dialogButton: {
    color: "white"
  },
  popovers: {
    zIndex: "10",
  },
  notificationCard: {
    marginTop: "1.5vh",
    marginBottom: "1.5vh",
    width: "90%",
    height: "10vh"
  }
}));

const Dashboard = (props) => {
  const classes = withStyles();
  const dispatch = props.dispatch;
  const { dashboardData, dashboardDataLoading, dashboardErrorMsg, dashboardError } = props;
  const [linkToken, setLinkToken] = useState(null);
  const LS_FIRST_TIME_MODALS = "pvFirstTimeModals";
  const showModalsLocalStorage = localStorage.getItem(LS_FIRST_TIME_MODALS);

  const [showErrorModal, setShowErrorModal] = useState(false);

  const onSuccess = useCallback(
    (token, metadata) => {
      dispatch(addLinkedAccount(token, metadata));
    },
    [dispatch]
  );

  useEffect(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PAYVIEW_BASE_URL}/api/create_link_token`
      );
      console.log(response);
      console.log(`link token: ${response.data.link_token}`);
      setLinkToken(response.data.link_token);
    } catch (error) {
      console.log(error);
    }
  }, [setLinkToken]);

  useEffect(() => {
    dispatch(fetchDashboardData());
  }, []);

  console.log('dashboard data', dashboardData ?? dashboardData?.messages)

  const handleClick = () => {
    if (ready) {
      open();
    }
  };

  const config = {
    token: linkToken !== null ? linkToken : "",
    onSuccess,
  };

  const { open, ready, err } = usePlaidLink(config);

  const formatNumbers = (num) => {
    if (num % 1 !== 0) {
      let localeNum = num.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
      return localeNum
    } else {
      return num.toLocaleString();
    }
  };

  const sumBankAccountValue = (account) => {
    let accountSum = account.accounts.map(act => act.value).reduce((a,b) => a+b);
    return formatNumbers(accountSum)
  }

  const handleErrorModalClose= () => {
    setShowErrorModal(false);
    dispatch(clearDashboardError());
  };

    return (
      <div className={classes.dashboardRoot}>
        <Layout pageTitle={props.title} />
        <div className={classes.dbTopText}>
          <h2 style={{marginBottom: 0,  marginTop: 0, fontWeight: 400}}>Your Dashboard Hub</h2>
          <p style={{marginBottom: '3%', fontSize: '70%', marginTop: "0", fontWeight: "400"}}>Centralize your tasks—request funds, send emails, and calculate ROI—all from here.</p>
        </div>
        <div className={classes.fundsProofWrapper}>
          <Grid container spacing={2} style={{color: '#000', textAlign: 'left', paddingRight: "3%", paddingBottom: "2%"}}>
            <Grid item xs={8} style={{borderRight: '#bbb  solid', paddingLeft: '5%', paddingTop: '2%', marginTop: '2%', marginBottom: '5%'}}>
              <p style={{'margin':  0,'fontWeight': 'bold','fontSize': '70%', marginBottom: '5%'}}>Start a New Task</p>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Link to="/requestreport" style={{ textDecoration: 'none' }}>
                    <Card className={classes.requestCard}>
                      <img
                        className={classes.requestIcon}
                        src="/icons/BigIcon_Request.png"
                      />
                      <h3 className={classes.requestCardTitle}>
                        Request Proof of Funds
                      </h3>
                    </Card>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <Link to="#" style={{ textDecoration: 'none', opacity: '40%' }}>
                    <Card className={classes.requestCard}>
                      <img
                        className={classes.requestIcon}
                        src="/icons/BigIcon_Request.png"
                      />
                      <Typography className={classes.requestCardTitle}>
                        Generate an Email
                      </Typography>
                    </Card>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} style={{color: '#000', textAlign: 'left', paddingLeft: '3%', paddingTop: '2%', marginTop: '2%', maxHeight: '60vh', overflow: "scroll"}} >
              <p style={{'margin':  0,'fontWeight': 'bold','fontSize': '70%'}}>Notifications</p>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody>
                  {dashboardData && dashboardData.messages && dashboardData.messages.map((msg) => (
                    <TableRow
                      key={msg.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <Card className={classes.notificationCard} style={{justifyContent: "left", display: "flex", paddingRight:"1%"}}>
                        <SvgIcon style={{height: "100%", marginLeft: "3%"}} >
                          {/* credit: cog icon from https://heroicons.com */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 48 48"
                            strokeWidth={4}
                            stroke="#549F9E"
                          >
                            <circle
                              cx="26" cy="26" r="20"
                              // strokeLinecap="round"
                              // strokeLinejoin="round"
                              // d="M4.5 12a7.5 7.5 0 0015 0m-15 0a7.5 7.5 0 1115 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077l1.41-.513m14.095-5.13l1.41-.513M5.106 17.785l1.15-.964m11.49-9.642l1.149-.964M7.501 19.795l.75-1.3m7.5-12.99l.75-1.3m-6.063 16.658l.26-1.477m2.605-14.772l.26-1.477m0 17.726l-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205L12 12m6.894 5.785l-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864l-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495"
                            />
                          </svg>
                        </SvgIcon>
                        <div style={{height: "100%", display:"flex", flexDirection: "column", justifyContent: "center", paddingLeft: "4%", width: "73%"}}>
                          <Typography style={{fontWeight: "bold", fontSize: ".6em"}}>{msg.message_subject}</Typography>
                          <Typography noWrap="true" style={{fontSize: ".4em"}}>{msg.to_user} - {msg.message_date}</Typography>
                        </div>
                        <Typography style={{height: "100%", display:"flex", flexDirection: "column", justifyContent: "center", paddingLeft: "4%", float: "right", fontWeight: "bold", fontSize: "1.2em", color: "#549F9E"}}>{">"}</Typography>
                      </Card>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>

        </div>
        
        <Dialog
            open={(dashboardDataLoading) && !dashboardError}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent className={classes.loadingDialog} style={{display: 'flex', justifyContent: 'center'}}>
                <Typography variant='h6' className={classes.dialogText} style={{display: 'flex', justifyContent: 'center'}}>
                    <CircularProgress className={classes.spinner} color="inherit" />
                      Loading Your Dashboard...
                </Typography>
            </DialogContent>
        </Dialog>
        <Dialog
            open={dashboardError}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle className={classes.loadingDialog}>ERROR</DialogTitle>
            <DialogContent className={classes.loadingDialog} style={{display: 'flex', justifyContent: 'center'}}>
                <Typography variant='h6' className={classes.dialogText} style={{display: 'flex', justifyContent: 'center'}}>
                        {dashboardErrorMsg ? dashboardErrorMsg : "Failed to load dashboard. Please try again."}
                </Typography>
            </DialogContent>
            <DialogActions className={classes.loadingDialog}>
                <Button className={classes.dialogButton} onClick={() => handleErrorModalClose()}>
                Okay
                </Button>
            </DialogActions>
        </Dialog>
      </div>
    );
};

export default connect((state) => ({
  dashboardData: state.dashboardData,
  dashboardDataLoading: state.dashboardDataLoading,
  dashboardErrorMsg: state.dashboardErrorMsg,
  dashboardError: state.dashboardError,
}))(Dashboard);
